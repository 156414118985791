import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/index.css'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$http = axios
// Vue.prototype.$url = 'http://192.168.1.116:12333'
Vue.prototype.$url = 'http://' + window.location.host

// Vue.prototype.$url = 'http://192.168.0.176:1333'

Vue.prototype.$messageDef = function (type, message) {
  this.$message({
    type: type,
    message: message
  })
}
Event = new Vue()
// request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('token')
  const user = window.localStorage.getItem('name')
  const UserId = window.localStorage.getItem('userId')
  // const RealName = window.localStorage.getItem('realName')
  const Identity = window.localStorage.getItem('identity')
  if (token) { // 设置请求头
    config.headers.Authorization = token 
    config.headers.Username = user
    config.headers.userId = UserId
    // config.headers.realName = RealName 
    config.headers.identity = Identity
  }
  return config
}, function (error) {
  // 如果请求出错在此执行某些操作
  console.log('请求出错')
  return Promise.reject(error)
})

// 添加一个响应拦截器
axios.interceptors.response.use(function (response) {
  // 登录超时
  if (response.data.code === 400) {
    router.push('/')
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

