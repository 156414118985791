import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '',
        name: '首页',
        component: () => import('@/views/indexView.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/card',
        name: '考试列表',
        component: () => import('../views/TaskCard.vue'),
        meta: {
          breadNumber: 2,
          requireAuth: true,
          keepAlive: true
        }
      },
      {
        path: '/exam',
        name: '复杂考试',
        component: () => import('../views/examView.vue'),
        meta: {
          breadNumber: 2,
          requireAuth: true
        }
      },
      {
        path: '/task',
        name: '考试名称',
        component: () => import('../views/cardItem.vue'),
        meta: {
          breadNumber: 3,
          requireAuth: true
        },
        props: (route) => ({ uniqueKey: route.query.uniqueKey })
      },
      {
        path: '/info',
        name: '账户信息',
        component: () => import('../views/accountInfo.vue'),
        meta: {
          breadNumber: 2,
          requireAuth: true
        }
      },
      {
        path: '/message',
        name: '消息中心',
        component: () => import('../views/messageView.vue'),
        meta: {
          breadNumber: 2,
          requireAuth: true
        }
      }, {
        path: '/examSchedule',
        name: '考试日程',
        component: () => import('../views/examSchedule.vue'),
        meta: {
          breadNumber: 2,
          requireAuth: true
        }
      }
    ],
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: '登录页',
    component: () => import('@/views/loginPage.vue')
  }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((r) => r.meta.requireAuth)) {
    const token = window.localStorage.getItem('token')
    if (token) { // 判断是否已经登录
      console.log('这是通过拦截后到处理', from)
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath } // 登录成功后重定向到当前页面
      })
    }
  } else {
    console.log('这是拦截')
    next()
  }
  // 如果本地 存在 token 则 不允许直接跳转到 登录页面
  if (to.fullPath === '/login') {
    if (localStorage.getItem('token')) {
      next({
        path: from.fullPath
      })
    } else {
      next()
    }
  }
})

export default router
